import {
  ProjectPermissionBlock,
  ProjectPermissions
} from '@/core/types/user-permissions.types';

import { useCurrentProject } from '../query-hooks/use-current-project/use-current-project';
import { useFeatureFlags } from '../use-feature-flags/use-feature-flags';

export const PROJECT_PERMISSION_KEYS: (keyof ProjectPermissions)[] = [
  'configure_crown_logic',
  'configure_human_feedback',
  'create_run',
  'create_stage',
  'delete',
  'delete_data',
  'delete_run',
  'edit_alert',
  'edit_stage',
  'export_data',
  'log_data',
  'move_run',
  'record_human_feedback',
  'rename',
  'rename_run',
  'share',
  'toggle_metric',
  'update',
  'dismiss_alert',
  'edit_edit',
  'edit_run_tags',
  'edit_slice',
  'set_metric'
];

export const defaults: ProjectPermissions = PROJECT_PERMISSION_KEYS.reduce(
  (acc, key) => ({ ...acc, [key]: { allowed: false, message: '' } }),
  {} as ProjectPermissions
);

export function useProjectPermissions() {
  // Feature Flags
  const { projectPermissions } = useFeatureFlags();
  const isEnabled = projectPermissions.isEnabled;
  const flagPermissions = projectPermissions.values;

  // Query Hooks
  const { data: currentProject, isLoading } = useCurrentProject();
  const currentProjectPermissions =
    (currentProject?.permissions as ProjectPermissionBlock[]) || [];

  /** @description Utility to get permissions of a specific project. useful if looping through a list of projects that may
   * have different permissions
   */
  function getProjectPermissions(
    permissions: ProjectPermissionBlock[]
  ): ProjectPermissions {
    if (isEnabled) {
      return flagPermissions;
    }

    // Convert permissions to Project Permissions
    const permissionBlock = Object.fromEntries(
      permissions.map(({ action, ...permission }) => [action, permission])
    ) as ProjectPermissions;


    // Reduce over actions and merge permissions with defaults
    return PROJECT_PERMISSION_KEYS.reduce((acc, key) => {
      acc[key] = permissionBlock[key] || defaults[key];
      return acc;
    }, {} as ProjectPermissions);
  }

  return {
    getProjectPermissions,
    currentProjectPermissions: getProjectPermissions(currentProjectPermissions),
    isLoading
  };
}

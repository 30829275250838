import { Divider, Group, Stack, Text } from '@mantine/core';
import {
  IconChartDots3,
  IconCurrencyDollar,
  IconGavel,
  IconWand
} from '@tabler/icons-react';

import CopyBox from '../copy-box/copy-box';

const MetricExplanationBody = ({
  explanation,
  message,
  rationale,
  rationaleLabel = 'Generated Reasoning',
  cost,
  model,
  judges,
  formatCost
}: {
  message?: string | null;
  explanation: string | null | undefined;
  rationale: string | null | undefined;
  rationaleLabel?: string;
  cost: number | null | undefined;
  model?: string | null;
  judges?: number | null;
  formatCost?: (cost: number) => string | number | JSX.Element;
}) => {
  return (
    <>
      {message && (
        <>
          <CopyBox
            copyText={message}
            data-testid='metric-message'
            mah='150px'
            px='md'
            py='sm'
            style={{ overflowY: 'auto' }}
          >
            <Text size='sm'>{message}</Text>
          </CopyBox>
        </>
      )}
      {explanation && (
        <CopyBox
          copyText={explanation}
          data-testid='metric-explanation'
          mah='150px'
          px='md'
          py='sm'
          style={{ overflowY: 'auto' }}
          variant='dark'
        >
          <Text size='sm'>{explanation}</Text>
        </CopyBox>
      )}
      {rationale && (
        <>
          <Divider />
          <CopyBox
            copyText={rationale}
            data-testid='metric-rationale'
            mah='150px'
            style={{ overflowY: 'auto' }}
          >
            <Group gap='0.5rem' px='md' py='sm'>
              <IconWand size='1rem' />
              <Text size='sm'>{rationaleLabel}</Text>
            </Group>
            <Text pb='sm' px='md' size='sm'>
              {rationale}
            </Text>
          </CopyBox>
        </>
      )}
      {(cost || model || judges) && (
        <>
          <Divider />
          <Stack gap='0.25rem' px='md' py='sm'>
            {model && (
              <Group data-testid='metric-model' gap='0.25rem' wrap='nowrap'>
                <IconChartDots3 size='1rem' />
                <Text truncate maw='15rem' size='sm'>
                  {model}
                </Text>
              </Group>
            )}
            <Group>
              {cost && (
                <Group data-testid='metric-cost' gap='0.5rem'>
                  <IconCurrencyDollar size='1rem' />
                  <Text size='sm'>
                    {formatCost != null ? formatCost(cost) : cost}
                  </Text>
                </Group>
              )}
              {judges && (
                <Group data-testid='metric-judges' gap='0.5rem'>
                  <IconGavel size='1rem' />
                  <Text size='sm'>
                    {judges} {`Judge${+judges > 1 ? 's' : ''}`}
                  </Text>
                </Group>
              )}
            </Group>
          </Stack>
        </>
      )}
    </>
  );
};

export default MetricExplanationBody;

import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { useEffect } from 'react';

import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';

const PostHogProvider = ({ children }: { children: React.ReactNode }) => {
  const { posthog: posthogFlag } = useFeatureFlags();
  const router = useRouter();
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (posthogFlag.isEnabled && process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
      if (typeof window !== 'undefined') {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY || '', {
          api_host: 'https://us.i.posthog.com'
        });
      }
    }
  }, [posthogFlag.isEnabled]);

  useEffect(() => {
    if (user?.id) {
      posthog.identify(user.id);
      // Track page views
      const handleRouteChange = () => posthog?.capture('$pageview');
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    } else {
      posthog.reset();
      return;
    }
  }, [user?.id]);

  return <PHProvider client={posthog}>{children}</PHProvider>;
};

export default PostHogProvider;

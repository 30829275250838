import { ASC, DESC, Tab } from '@/core/constants/query-params.constants';
import { MetricsGroupId } from '@/core/hooks/use-metrics-group/use-metrics-group';
import { QueryFilter } from '@/core/types/filters.types';

export type ChartOrder = {
  [key in MetricsGroupId]?: string[];
};

export const DRAWER_TAB = {
  METRICS: 'metrics',
  PARAMETERS: 'parameters',
  FEEDBACK: 'feedback'
} as const;
export type DrawerTab = (typeof DRAWER_TAB)[keyof typeof DRAWER_TAB];

export type ObserveParameters = {
  chainNodeId?: string;
  chainRootId?: string;
  chartsPerRow?: number;
  drawerTab: DrawerTab;
  expandedChart?: string | null;
  groupedBy: string;
  hiddenPromptMonitorCharts: string[];
  hiddenPromptMonitorGroups: MetricsGroupId[];
  pageSize: number;
  projectId?: string;
  project_id?: string;
  promptMonitorChartOrder: ChartOrder;
  promptMonitorFilters: QueryFilter[];
  promptMonitorGroupOrder: MetricsGroupId[];
  refreshInterval?: number;
  searchTerm?: string;
  showChains: boolean;
  showChartsLegends: boolean;
  showContinuousCharts: boolean;
  sortBy: string;
  sortDirection: typeof ASC | typeof DESC;
  tab: Tab;
  timeRange: string;
  tokenHighlight?: string;
};

export type ObserveParametersKey = keyof ObserveParameters;
export type ObserveParameterEntry = ObserveParameters[ObserveParametersKey];

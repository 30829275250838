import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import { getProcDataMetrics } from '@/fine-tune/data-parsers/get-proc-data-metrics/get-proc-data-metrics';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';
import { EditAction } from '@/fine-tune/stores/data-edits-store/data-edits.store.types';
import {
  useComputedParameters,
  useParametersStore
} from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { CreateEditArgs } from '@/fine-tune/types/mutation.types';
import {
  FilterParams,
  InsightsRowsResponse
} from '@/fine-tune/types/query.types';
import { getChangesByLabel } from '@/fine-tune/utils/get-changes-by-label/get-changes-by-label';
import { updateEditedRows } from '@/fine-tune/utils/update-edited-rows/update-edited-rows';

import { GET_EDITS } from '../use-edits/use-edits';
import { useInsightsRows } from '../use-insights-rows/use-insights-rows';
import { useInsightsRowsQueryKey } from '../use-insights-rows/use-insights-rows-query-key';
import { getTotalSamplesIds } from '../../../utils/get-total-samples-ids/get-total-samples-ids';

export const POST_CREATE_EDIT =
  '/projects/{project_id}/runs/{run_id}/split/{split}/edits';

interface CreateDataEditArgs {
  sampleIds: number[];
  editAction: EditAction;
  newText?: string;
}

interface Parameters {
  project_id: string;
  run_id: string;
  split: string;
  filter?: Partial<FilterParams>;
  sample_ids?: number[];
  edit_action: EditAction;
  search_string?: string;
  text_replacement?: string;
  shift_span_start_num_words?: number;
  shift_span_end_num_words?: number;
  note?: string;
  new_label?: string;
}

/**
 * useEditCreate
 *
 */
export const useEditCreate = (rowId?: number, key?: 'gold' | 'pred') => {
  const queryClient = useQueryClient();

  const insightsRowsKey = useInsightsRowsQueryKey();

  const { projectId, runId } = usePathParameters();
  const split = useParametersStore((s) => s.split);

  const { isNer, isOd, isInference } = useComputedParameters();

  const insightsRows = useInsightsRows();
  // @ts-expect-error TODO: Fix typing
  const { data } = getProcDataMetrics(insightsRows);
  const filterParams = useFilterParams();

  // Data edits store
  const replaceTerm = useDataEditsStore((state) => state.replaceTerm);
  const searchTerm = useDataEditsStore((state) => state.searchTerm);
  const isRegexSearch = useDataEditsStore((state) => state.isRegexSearch);
  const spanStartIndex = useDataEditsStore((state) => state.spanStartIndex);
  const spanEndIndex = useDataEditsStore((state) => state.spanEndIndex);
  const changeLabelTo = useDataEditsStore((state) => state.changeLabelTo);
  const currentTab = useDataEditsStore((state) => state.currentTab);
  const note = useDataEditsStore((state) => state.notes);

  // Global Store
  const similarToIds = useStore((state) => state.similarToIds);
  const allRowsSelected = useStore((state) => state.allRowsSelected);
  const selectedRows = useStore((state) => state.selectedRows);
  const selectedSpans = useStore((state) => state.selectedSpans);
  const selectedObjects = useStore((state) => state.selectedObjects);
  const { setSelectedObjects, setSelectedRows, setSelectedSpans } = useStore(
    (state) => state.actions
  );

  const selectedSpansIds = selectedSpans.map(({ id }) => id);

  const samplesIds = useMemo(
    () =>
      // TODO: Improve typing
      typeof rowId === 'number'
        ? [rowId]
        : getTotalSamplesIds(data || [], selectedRows, selectedSpansIds),

    [selectedRows, selectedSpansIds, isNer, rowId, data?.length]
  );

  const createEdit = async ({
    note,
    edit_action,
    new_label
  }: CreateEditArgs) => {
    const body = buildBody(false, edit_action, note, new_label);
    return createEditFetcher(body);
  };

  const createDataEdit = async ({
    editAction,
    sampleIds,
    newText
  }: CreateDataEditArgs) => {
    const body = buildBody(true, editAction, note, undefined, newText);

    if (body.filter) {
      if (isNer) {
        body.filter.span_sample_ids = sampleIds;
      } else {
        body.filter.ids = sampleIds;
      }
    }

    return createEditFetcher(body);
  };

  const createEditFetcher = async (body: Parameters) => {
    const res = await api.POST(POST_CREATE_EDIT, {
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!,
          split
        }
      },
      // @ts-expect-error TODO: Fix typing
      body
    });

    return res?.data;
  };

  const buildBody = (
    isDataEdit: boolean,
    editAction: EditAction,
    note: string | undefined,
    newLabel?: string,
    newText?: string
  ) => {
    const commonBody: Parameters = {
      project_id: projectId!,
      run_id: runId!,
      split,
      edit_action: editAction,
      note
    };

    let filters: Partial<FilterParams> = filterParams;
    if (!allRowsSelected && (samplesIds.length || isOd)) {
      if (isOd) {
        filters.ids = selectedObjects;
      } else {
        filters.ids = samplesIds;
      }
    } else {
      if (samplesIds?.length) {
        filters.exclude_ids = samplesIds;
      }
    }

    commonBody.filter = filters;

    if (isDataEdit) {
      return {
        ...commonBody,
        use_regex: isRegexSearch,
        search_string: searchTerm,
        text_replacement: newText || replaceTerm,
        shift_span_start_num_words: spanStartIndex,
        shift_span_end_num_words: spanEndIndex,
        new_label: currentTab === 'shift' ? undefined : changeLabelTo
      };
    } else {
      return {
        ...commonBody,
        new_label: newLabel
      };
    }
  };

  const createEditHandlers = {
    onSuccess: (response: any) => {
      const samplesIds = response?.sample_ids || [];
      const isSingular = samplesIds.length === (isNer ? 2 : 1);
      const editText = `Sample${isSingular ? '' : 's'}`;
      const isRelabeling = ['relabel', 'relabel_as_pred'].includes(
        response?.edit_action
      );

      showNotification({
        title: 'Success',
        message: `${editText} added to ${
          isInference ? 'Labels' : 'Edits'
        } Cart!`,
        autoClose: 2000
      });

      setSelectedObjects([]);
      setSelectedRows([]);
      setSelectedSpans([]);

      queryClient.invalidateQueries([GET_EDITS]);
      queryClient.setQueryData(
        insightsRowsKey,
        // @ts-expect-error TODO: Fix typing
        (oldData: { pages: InsightsRowsResponse[] }) => {
          return updateEditedRows({
            oldData,
            samplesIds,
            isDeleting: false,
            isRelabeling,
            isNer
          });
        }
      );
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error creating edit')
  };

  const createEditMutation = useMutation(createEdit, createEditHandlers);

  const createDataEditMutation = useMutation(
    createDataEdit,
    createEditHandlers
  );

  const changesByLabel = useMemo(
    () =>
      getChangesByLabel(
        data || [],
        rowId ? [rowId] : selectedRows,
        similarToIds,
        allRowsSelected,
        selectedObjects,
        key,
        selectedSpans
      ),

    [
      selectedObjects,
      selectedRows,
      similarToIds,
      allRowsSelected,
      isNer,
      rowId,
      data?.length
    ]
  );

  const predChanges = useMemo(
    () =>
      getChangesByLabel(
        data || [],
        rowId ? [rowId] : selectedRows,
        similarToIds,
        allRowsSelected,
        selectedObjects,
        'pred'
      ),

    [
      selectedObjects,
      selectedRows,
      similarToIds,
      allRowsSelected,
      isNer,
      rowId,
      data?.length
    ]
  );

  const getChangesToSameLabelAmount = (
    relabelsToPred: boolean,
    label: string
  ) =>
    selectedRows.reduce((acc, rowId) => {
      const row = data?.find((row) => {
        return row.id === rowId;
      });
      const actualGold = row?.gold;

      if (relabelsToPred && actualGold === row?.pred) {
        return acc + 1;
      }

      if (actualGold === label) {
        return acc + 1;
      }

      return acc;
    }, 0);

  const predChangesByLabel = Object.keys(predChanges);

  return {
    createEditMutation,
    createDataEditMutation,
    changesByLabel,
    getChangesToSameLabelAmount,
    changesToSamePred:
      predChangesByLabel.length === 1 ? predChangesByLabel[0] : null,
    samplesIds
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import ErrorMessage from '@/core/components/atoms/error-message/error-message';
import { INTEGRATIONS_PATH } from '@/core/constants/api.constants';
import { IntegrationIds } from '@/core/constants/integrations.constants';
import { UpdateIntegrationMutationBody } from '@/core/modals/integrations-modal/integrations-modal';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { GET_INTEGRATION } from '../use-integration/use-integration';

interface ReqBody {
  endpoint?: string;
  url?: string;
  token?:
    | string
    | {
        aws_access_key_id?: string;
        aws_secret_access_key?: string;
        aws_role_arn?: string;
      };
  is_enterprise?: boolean;
  organization?: string;
  hostname?: string;
  path?: string;
  catalog_name?: string;
  principal_id?: string;
  default_catalog_name?: string;
  organization_id?: string;
  region?: string;
  credential_type?: string;
}

export const useUpdateIntegration = (
  id: IntegrationIds,
  onSuccessCallback?: () => void
) => {
  const queryClient = useQueryClient();

  let path = `/integrations/${id}`;

  const isDatabricks = id === 'databricks';

  const fetchUpdateIntegration = async (
    params: UpdateIntegrationMutationBody
  ) => {
    const {
      url,
      token,
      isEnterprise,
      organization,
      sqlPath,
      catalogName,
      principalId,
      ...rest
    } = params;

    let body = {
      url,
      token,
      is_enterprise: isEnterprise,
      organization
    } as ReqBody;

    if (isDatabricks) {
      path = `${path}/unity-catalog/sql`;
      body.hostname = url;
      body.path = sqlPath;
      body.principal_id = principalId;
      body.default_catalog_name = catalogName;
      delete body.url;
    }

    if (id === 'azure') {
      body.endpoint = url;
      delete body.url;
    }

    if (id === 'writer') {
      body.organization_id = organization;
    }

    if (id === 'aws_bedrock' || id === 'aws_sagemaker') {
      body = {
        ...rest,
        credential_type: params.credentialType,
        token: {
          aws_access_key_id: params.aws_access_key_id,
          aws_secret_access_key: params.aws_secret_access_key,
          aws_role_arn: params.aws_role_arn
        }
      };
    }

    const res = await api
      // @ts-expect-error TODO! TypeScript these endpoints.
      .PUT(path, { body })
      .then((res) => {
        if (res.response.status === 200) {
          queryClient.invalidateQueries([INTEGRATIONS_PATH]);
          queryClient.invalidateQueries([GET_INTEGRATION, id]);
          showNotification({
            title: 'Success!',
            message: 'Integration has been activated',
            type: 'success'
          });
          onSuccessCallback?.();
        }

        if (res.response.status !== 200) {
          showNotification({
            autoSize: true,
            title: `Unable to link with ${id}`,
            // @ts-expect-error incorrect type from API
            message: res.error?.detail,
            type: 'error'
          });
        }

        return res.data;
      })
      .catch(async (error) => {
        const err = await error.json();

        const formattedErr = {
          response: {
            data: err
          }
        };

        showNotification({
          autoSize: true,
          title: `Unable to link with ${id}`,
          message: <ErrorMessage error={formattedErr as any} />,
          type: 'error'
        });
        return error;
      });

    return res;
  };

  const updateIntegration = useMutation(fetchUpdateIntegration);

  return updateIntegration;
};

export const LOGIN_PATH = '/login';
export const SOCIAL_LOGIN_PATH = '/login/social';
export const CURRENT_USER_PATH = '/current_user';
export const ADMIN_USER_CHECK_PATH = '/users/admin';

// Integrations
export const INTEGRATIONS_PATH = '/integrations';

// Projects
export const PROJECTS_PATH = '/projects/paginated' as const;
export const PROJECT_PATH = '/projects/{project_id}' as const;
export const PROJECT_BOOKMARK_PATH = '/projects/{project_id}/bookmark' as const;
export const PROJECT_CREATE_PATH = '/projects' as const;

// Invite Users
export const INVITE_USERS_PATH = '/invite_users';

// Users
export const USER_PATH = '/users/{user_id}';
export const USERS_PATH = '/users';
export const USERS_ALL_PATH = '/users/all';
export const USER_ROLES_PATH = '/user_roles';

// User Groups
export const CURRENT_USER_GROUPS_PATH = '/current_user/groups';

export const USER_GROUPS_PATH = '/groups';
export const USER_GROUP_PATH = '/groups/{group_id}';
export const USER_GROUP_MEMBERS_PATH = '/groups/{group_id}/members';
export const USER_GROUP_MEMBER_PATH = '/groups/{group_id}/members/{user_id}';

// RBAC Roles
export const COLLABORATOR_ROLES_PATH = '/collaborator_roles';
export const USER_GROUP_ROLES_PATH = '/group_roles';

// Project Collaborators
export const USER_PROJECT_COLLABORATORS_PATH = '/projects/{project_id}/users';
export const USER_PROJECT_COLLABORATOR_PATH =
  '/projects/{project_id}/users/{user_id}';

export const GROUP_PROJECT_COLLABORATORS_PATH = '/projects/{project_id}/groups';
export const GROUP_PROJECT_COLLABORATOR_PATH =
  '/projects/{project_id}/groups/{group_id}';

// Human Feedback
export const HUMAN_FEEDBACK_TEMPLATES_PATH =
  '/projects/{project_id}/feedback/templates';

export const HUMAN_FEEDBACK_TEMPLATE_PATH =
  '/projects/{project_id}/feedback/templates/{template_id}';

export const HUMAN_FEEDBACK_RATING_BY_RUN_ID_PATH =
  '/projects/{project_id}/runs/{run_id}/feedback/templates/{template_id}/index/{index}/rating';

export const HUMAN_FEEDBACK_RATING_BY_NODE_ID_PATH =
  '/projects/{project_id}/feedback/templates/{template_id}/nodes/{node_id}/rating';

// Global Paths
export const PROJECT_RUN_PATH = '/projects/{project_id}/runs/{run_id}';
export const PROJECT_RUN_METRICS_PATH =
  '/projects/{project_id}/runs/{run_id}/metrics';

// Evaluate Paths
export const EVALUATE_RUNS_PATH = '/projects/{project_id}/prompts/runs';
export const EVALUATE_RUN_PATH = '/projects/{project_id}/prompts/runs/{run_id}';
export const EVALUATE_METRICS_PATH =
  '/projects/{project_id}/runs/{run_id}/prompts/metrics';

export const EVALUATE_COLUMNAR_RUNS_PATH =
  '/projects/{project_id}/prompts/runs/columnar';
export const EVALUATE_COLUMNAR_RUN_PATH =
  '/projects/{project_id}/runs/{run_id}/prompts/rows/columnar';
export const EVALUATE_COLUMNAR_RUNS_ROWS_PATH =
  '/projects/{project_id}/runs/prompts/rows/columnar';

export const EVALUATE_CHAINS_ROWS_PATH = `/projects/{project_id}/runs/{run_id}/prompts/rows/chains/{chain_id}/columnar`;
export const EVALUATE_CHAINS_ROWS_PATH_FULL = `/projects/{project_id}/runs/{run_id}/prompts/rows/chains/{chain_id}/columnar-full-chain`;
export const EVALUATE_CHAINS_ROWS_PATH_PARTIAL = `/projects/{project_id}/runs/{run_id}/prompts/rows/chains/{chain_id}/columnar-partial-chain`;

import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { FeatureFlagsStoreState } from './feature-flags.store.types';

export const useFeatureFlagsStore =
  createWithEqualityFn<FeatureFlagsStoreState>()(
    (set, get) => ({
      airgapped: false,
      featureFlags: {
        csv_training: 'disabled',
        gleap: 'disabled',
        hide_credentials_login: 'disabled',
        hide_request_access: 'disabled',
        image_classification: 'disabled',
        label_co_occurrence: 'disabled',
        monitor_alerts: 'disabled',
        object_detection: 'disabled',
        observe_human_ratings: 'disabled',
        posthog: 'disabled',
        prompt_eval: 'disabled',
        prompt_level_uncertainty: 'disabled',
        prompt_monitor: 'disabled',
        prompt_scorers_default_all: 'disabled'
      },

      actions: {
        setFeatureFlags: (flags) =>
          set(() => ({
            featureFlags: flags?.feature_flags,
            airgapped: flags?.airgapped
          }))
      },

      computed: {
        getCoOccurrenceFlag: () =>
          get()?.featureFlags?.label_co_occurrence === 'enabled',

        getCSVTrainingFlag: () =>
          get()?.featureFlags?.csv_training === 'enabled',

        getGleapFlag: () => get()?.featureFlags?.gleap === 'enabled',

        getHideCredentialsLoginFlag: () =>
          get()?.featureFlags?.hide_credentials_login === 'enabled',

        getHideRequestAccessFlag: () =>
          get()?.featureFlags?.hide_request_access === 'enabled',

        getObjectDetectionFlag: () =>
          get()?.featureFlags?.object_detection === 'enabled',

        getObserveAlertsFlag: () =>
          get()?.featureFlags?.monitor_alerts === 'enabled',

        getObserveHumanRatingsFlag: () =>
          get()?.featureFlags?.observe_human_ratings === 'enabled',

        getObserveFlag: () => get()?.featureFlags?.prompt_monitor === 'enabled',

        getPosthogFlag: () => get()?.featureFlags?.posthog === 'enabled',

        getPromptFlag: () => get()?.featureFlags?.prompt_eval === 'enabled',

        getPromptLevelUncertaintyFlag: () =>
          get()?.featureFlags?.prompt_level_uncertainty === 'enabled',

        getPromptScorersDefaultAllFlag: () =>
          get()?.featureFlags?.prompt_scorers_default_all === 'enabled'
      }
    }),
    shallow
  );

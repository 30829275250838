import createClient, { ParamsOption, RequestBodyOption } from 'openapi-fetch';

import { authMiddleware, CSPMiddleware, NlpRequestMiddleware } from './fetcher';
import { paths } from '../types/api';

// This will be replaced by the actual API URL in the Next Middleware.
// This is a workaround to use runtime env variables easily in the client side.
export const API_URL = '/api/galileo';

// This is the actual API URL that is safe __only__ for server side requests.
export const SERVER_SAFE_API_URL = process.env.GALILEO_API_URL;

export type UseQueryOptions<T> = ParamsOption<T> &
  RequestBodyOption<T> & {
    reactQuery: {
      enabled: boolean;
    };
  };

const api = createClient<paths>({
  baseUrl: API_URL
});

// Heads up: Order sets the priority of middleware
api.use(CSPMiddleware);
api.use(authMiddleware);
api.use(NlpRequestMiddleware);

export default api;

import { DESC, Tab } from '@/core/constants/query-params.constants';

import { DRAWER_TAB, ObserveParameters } from './query-parameters.types';

export const defaultStoreParams: ObserveParameters = {
  chainNodeId: undefined,
  chainRootId: undefined,
  chartsPerRow: 3,
  drawerTab: DRAWER_TAB.METRICS,
  expandedChart: undefined,
  groupedBy: '',
  hiddenPromptMonitorCharts: [],
  hiddenPromptMonitorGroups: [],
  pageSize: 25,
  projectId: undefined,
  project_id: undefined,
  promptMonitorFilters: [],
  promptMonitorChartOrder: {},
  promptMonitorGroupOrder: [],
  refreshInterval: 60000, // Every 1 minute
  searchTerm: '',
  showChains: true,
  showContinuousCharts: false,
  showChartsLegends: false,
  sortBy: 'created_at',
  sortDirection: DESC,
  tab: Tab.OBSERVE_METRIC_VIEW,
  timeRange: 'last3Hours',
  tokenHighlight: undefined
};

globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"refs/tags/v2.3.54"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

// Disable on local development
const enabled = process.env.NODE_ENV !== 'development';

// Fetch ENV variables from server, then initialize Sentry
axios.get('/api/config').then((res) => {
  const { GALILEO_CUSTOMER_NAME, SENTRY_DSN, SENTRY_RELEASE } = res.data;
  
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error']
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 599]
      })
    ],
    tracesSampleRate: 0.1,
    profilesSampleRate: 0,
    enabled,
    environment: GALILEO_CUSTOMER_NAME,
    release: SENTRY_RELEASE
  });
})

import { useSessionStorage } from '@mantine/hooks';

import { FlagTags } from '@/core/types/feature-flags.types';

export const useABFlags = () => {
  const [customMetricsFlag, setCustomMetricsFlag] = useSessionStorage({
    key: 'custom_metrics',
    defaultValue: false
  });

  return {
    customMetrics: {
      description: 'Toggle Custom Metrics.',
      isEnabled: customMetricsFlag,
      tag: [FlagTags.Feature],
      title: 'Custom Metrics',
      toggle: () => setCustomMetricsFlag(!customMetricsFlag)
    }
  };
};
